import React from "react";
// import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const handleDonationClick = () => {
	navigator.clipboard.writeText('jesse@jesserivest.com').then(
		() => window.alert('Copied! In Clipboard now; go to your banking app.')
	);
};

const clickDoNothing = (e) => console.log();//e.preventDefault();

const IntakePage = () => {
	return (
	  <Layout>
	    <Seo title="Intake" />
		<p>&nbsp;</p>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/photos/pressRes/_MG_1300.jpg"
			alt="Jesse Rivest press image"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-large"
			width={500}
			/>
		</div>
		<div className="main--centerwrapper main--centerwrapper__column">
			<h2 className="main--centered">A pleasure meeting you!</h2>
		</div>

		<h3 className="centeredText" style={{marginBottom: "1.5rem"}}>
			Updates every 3 – 4 months
		</h3>
		<p className="centeredText card">
			<a href="//bit.ly/email-list-JR"><button className="card" onClick={clickDoNothing}>E-mail</button></a>
		</p>
		<p className="centeredText">
			or
		</p>
		<p className="centeredText card">
			<a href={encodeURI('//wa.me/5561981399648?text=Please send me music updates')}><button className="card" onClick={clickDoNothing}>Whatsapp</button></a>
		</p>
		<h3 className="centeredText" style={{marginBottom: "1.5rem"}}>
			Donations via PIX or Interac
		</h3>
		<p className="centeredText card">
			<button className="card" onClick={handleDonationClick}>jesse@jesserivest.com</button>
		</p>

	  </Layout>
  );
}
export default IntakePage
